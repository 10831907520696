import getConfig from "next/config";
import Head from "next/head";

import PropTypes from "prop-types";

const { publicRuntimeConfig } = getConfig();
const { publicOptions } = publicRuntimeConfig;
const path = require("path");

/** ========== */

/**
 *
 * @param {{
 * image;
 * src;
 * alt;
 * placeholder;
 * priority;
 * wrapper:{className,style};
 * width:String|Number;
 * heightString|Number;
 * }} props Props for the component
 *
 */
const ratioScreen = {
  sm: 375,
  md: 768,
  lg: 1920,
};

const ratioRange = [0, ratioScreen.sm, ratioScreen.md, ratioScreen.lg];

const genImageHead = (width, height, src, ratio) => {
  const ratioClone = [...ratio];
  const imgHeadList = [];

  if (width <= ratioClone[0].width) {
    imgHeadList.push(
      <link
        rel="preload"
        href={`${publicOptions.basePath}/${src}-${width}x${height}.webp`}
        as="image"
        type="image/webp"
        media={`(min-width: ${ratioRange[0]}px) and (max-width: ${
          ratioRange[1] - 1
        }px)`}
        key={0}
      />
    );
  } else {
    for (let index = 0; index < ratioClone.length; index++) {
      const element = ratioClone[index];

      // Width của image lớn hơn width của màn hình đang xét
      if (width >= element.width) {
        // Màn hình lớn nhất
        if (index === ratioClone.length - 1) {
          imgHeadList.push(
            <link
              rel="preload"
              href={`${publicOptions.basePath}/${src}-${element.width}x${element.height}.webp`}
              as="image"
              type="image/webp"
              media={`(min-width: ${ratioRange[index]}px) `}
              key={index}
            />
          );
        } else {
          // Không phải màn hình lớn nhất
          imgHeadList.push(
            <link
              rel="preload"
              href={`${publicOptions.basePath}/${src}-${element.width}x${element.height}.webp`}
              as="image"
              type="image/webp"
              media={`(min-width: ${ratioRange[index]}px) and (max-width: ${
                ratioRange[index + 1] - 1
              }px)`}
              key={index}
            />
          );
        }
      } else if (
        // Width của image nằm giữa width màn hình đang xét và width màn hình trước đó
        width > ratioClone[index - 1].width &&
        width <= ratioClone[index].width
      ) {
        imgHeadList.push(
          <link
            rel="preload"
            href={`${publicOptions.basePath}/${src}-${width}x${height}.webp`}
            as="image"
            type="image/webp"
            media={`(min-width: ${ratioRange[index]}px) `}
            key={index}
          />
        );
      }
    }
  }

  return imgHeadList.reverse();
};

const genImageSource = (width, height, src, ratio) => {
  const ratioClone = [...ratio];
  const imgSourceList = [];

  if (width <= ratioClone[0].width) {
    imgSourceList.push(
      <source
        type="image/webp"
        srcSet={`${publicOptions.basePath}/${src}-${width}x${height}.webp`}
        media={"(min-width: 240px)"}
        key={0}
      />
    );
  } else {
    for (let index = 0; index < ratioClone.length; index++) {
      const element = ratioClone[index];

      // Width của image lớn hơn kích thước của màn hình đang xét

      if (width >= element.width) {
        // Màn hình lớn nhất
        if (index === ratioClone.length - 1) {
          imgSourceList.push(
            <source
              type="image/webp"
              srcSet={`${publicOptions.basePath}/${src}-${element.width}x${element.height}.webp`}
              media={`(min-width: ${ratioRange[index]}px)`}
              key={index}
            />
          );
        } else {
          // Không phải màn hình lớn nhất
          imgSourceList.push(
            <source
              type="image/webp"
              srcSet={`${publicOptions.basePath}/${src}-${element.width}x${element.height}.webp`}
              media={`(min-width: ${ratioRange[index]}px) and (max-width: ${
                ratioRange[index + 1] - 1
              }px)`}
              key={index}
            />
          );
        }
      } else if (
        // Width của image nằm giữa kích thước màn hình đang xét so với màn hình trước đó
        width > ratioClone[index - 1].width &&
        width < ratioClone[index].width
      ) {
        imgSourceList.push(
          <source
            type="image/webp"
            srcSet={`${publicOptions.basePath}/${src}-${width}x${height}.webp`}
            media={`(min-width: ${ratioRange[index]}px)`}
            key={index}
          />
        );
      }
    }
  }

  return imgSourceList.reverse();
};

const Img = ({
  image,
  src,
  alt,
  placeholder,
  priority,
  wrapper,
  width,
  height,
  ...props
}) => {
  const srcStatic = src
    ? src.replace(/^\/+/u, "")
    : placeholder.replace(/^\/+/u, "");

  if (
    image &&
    image.constructor === Object &&
    Object.keys(image).length !== 0
  ) {
    const webpEnable = publicOptions?.options?.acf?.webpEnable;

    if (webpEnable) {
      const srcSet = [];

      const originalSize = `${image?.sourceUrl}.webp ${image?.mediaDetails?.width}w`;

      srcSet.push(originalSize);
      const sizes = image?.mediaDetails?.sizes || [];

      if (sizes.length > 0) {
        sizes.forEach((size) => {
          const newSize = `${size?.sourceUrl}.webp ${size?.width}w`;

          srcSet.push(newSize);
        });
      }

      return (
        <>
          <div {...wrapper}>
            <picture>
              <source
                type="image/webp"
                srcSet={srcSet.join(", ")}
                sizes={image?.sizes}
                {...props}
              />
              <img
                {...props}
                loading={`${priority ? null : "lazy"}`}
                decoding="async"
                src={image?.sourceUrl}
                srcSet={image?.srcSet}
                sizes={image?.sizes}
                width={image?.mediaDetails?.width}
                height={image?.mediaDetails?.height}
                alt={image?.altText ? image?.altText : alt}
                title={image?.altText ? image?.altText : alt}
              />
            </picture>
          </div>
        </>
      );
    }

    return (
      <>
        <div {...wrapper}>
          <img
            {...props}
            loading={`${priority ? null : "lazy"}`}
            decoding="async"
            src={image?.sourceUrl}
            srcSet={image?.srcSet}
            sizes={image?.sizes}
            width={image?.mediaDetails?.width}
            height={image?.mediaDetails?.height}
            alt={image?.altText ? image?.altText : alt}
          />
        </div>
      </>
    );
  }
  const extname = path.extname(srcStatic).toLowerCase();
  const isPng = extname.localeCompare(".png") == 0;
  const isJpeg =
    extname.localeCompare(".jpg") == 0 || extname.localeCompare(".jpeg") == 0;

  if (width && (isPng || isJpeg)) {
    const srcSplit = srcStatic?.split(".")[0];
    const srcArr = srcSplit?.split("/");
    const srcOptimize = `assets/optimize-images/${srcArr[srcArr.length - 1]}`;

    const ratioSm = ratioScreen.sm / width;
    const ratioMd = ratioScreen.md / width;
    const ratioLg = ratioScreen.lg / width;

    const sm = {
      width: ratioScreen.sm,
      height: Math.round(ratioSm * height),
    };
    const md = {
      width: ratioScreen.md,
      height: Math.round(ratioMd * height),
    };
    const lg = {
      width: ratioScreen.lg,
      height: Math.round(ratioLg * height),
    };

    return (
      <>
        {priority && (
          <Head>
            {genImageHead(width, height, srcOptimize, [sm, md, lg]).map(
              (item) => item
            )}
          </Head>
        )}
        <div {...wrapper}>
          <picture>
            {genImageSource(width, height, srcOptimize, [sm, md, lg]).map(
              (item) => item
            )}

            <img
              loading={`${priority ? null : "lazy"}`}
              decoding="async"
              src={`${publicOptions.basePath}/${srcStatic}`}
              alt={alt}
              width={width}
              height={height}
              {...props}
            />
          </picture>
        </div>
      </>
    );
  }

  return (
    <>
      {priority && (
        <Head>
          <link
            rel="preload"
            as="image"
            href={`${publicOptions.basePath}/${srcStatic}`}
          />
        </Head>
      )}
      <div {...wrapper}>
        <img
          loading={`${priority ? null : "lazy"}`}
          decoding="async"
          src={`${publicOptions.basePath}/${srcStatic}`}
          alt={alt}
          width={width}
          height={height}
          {...props}
        />
      </div>
    </>
  );
};

export default Img;

Img.propTypes = {
  wrapper: PropTypes.object,
  src: PropTypes.string,
  priority: PropTypes.bool,
  placeholder: PropTypes.string,
  image: PropTypes.object,
  alt: PropTypes.string,
};

Img.defaultProps = {
  wrapper: {
    className: "inline-block",
  },
  src: null,
  priority: false,
  placeholder: "assets/common/placeholder.jpg",
  image: {},
  alt: "placeholder",
};
