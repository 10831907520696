import { useEffect, useState } from "react";

import { FaCookieBite } from "components/atoms/Icon/fa/FaCookieBite";
import LinkRouter from "components/atoms/LinkRouter";

export default function Cookies() {
  const [show, setShow] = useState(false);
  const handleCookiesClick = () => {
    localStorage.setItem("statusPopup", 1);
    localStorage.setItem("expirationCookies", new Date().getTime());
    setShow(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const statusPopup = localStorage.getItem("statusPopup");
      const expirationCookies = localStorage.getItem("expirationCookies");

      if (
        !statusPopup ||
        Number.parseInt(statusPopup, 10) !== 1 ||
        !expirationCookies ||
        new Date().getTime() - expirationCookies > 7 * 86400000
      ) {
        setShow(true);
      }
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  return show ? (
    <div className="fixed bottom-12 left-4 right-4 z-50   md:left-12 md:max-w-[563px] ">
      <div className="rounded-xl bg-white py-6 px-4 text-black drop-shadow-[0px_0px_20px_rgba(124,120,120,0.2)] md:flex md:items-center ">
        <p className="md:pr-6">
          By using our website you consent to all cookies in accordance with our{" "}
          <LinkRouter
            href="/privacy-policy"
            className="text-bbc-primary"
            target="_blank"
            rel="nofollow"
          >
            Privacy Policy
          </LinkRouter>
        </p>
        <button
          onClick={handleCookiesClick}
          className="mt-2 whitespace-nowrap rounded-full bg-bbc-primary py-2 px-6 font-semibold text-white md:mt-0"
        >
          <FaCookieBite size={16} className="mr-2 inline-block" />
          Accept
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
}
